// require("turbolinks").start()
// import "../controllers"
import * as ActiveStorage from "@rails/activestorage"
// import "@hotwired/turbo"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
Turbo.setProgressBarDelay(200)
// console.log('turbo aktywne:', Turbo)

require("@rails/ujs").start()
ActiveStorage.start()
window.activeStorage = ActiveStorage
// require("channels")
// console.log('1111')

// window.$ = window.jQuery = require("jquery")

// class IntumApiUI {
//   static toggle () {
//     document.querySelectorAll('.intum-toggle').forEach(el =>
//       el.classList.toggle('intum-hidden')
//     )
//     // const elems = document.getElementsByClassName("intum-toggle")
//     // for (var i = 0; i < elems.length; i++) {
//     //   elems[i].classList.toggle('intum-hidden')
//     // }
//   }
// }

// window.IntumApiUI = IntumApiUI

// if (!window.location.pathname.includes('organize')) {
//   require("dropzone")
// }
